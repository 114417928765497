<script lang="ts">
	import { onMount } from 'svelte'
	import { browser } from '$app/environment'
	import { page } from '$app/stores'
	import { PUBLIC_FATHOM_SITE_ID, PUBLIC_FATHOM_URL } from '$env/static/public'
	import * as Fathom from 'fathom-client'

	onMount(async () => {
		Fathom.load(PUBLIC_FATHOM_SITE_ID, {
			url: PUBLIC_FATHOM_URL,
		})
	})

	$: browser && $page.url.pathname && Fathom.trackPageview()
</script>
